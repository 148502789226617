import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTrash } from '@fortawesome/free-solid-svg-icons';

interface EllipsisOptionsProps {
    onDelete: () => void;
}

const EllipsisOptions: React.FC<EllipsisOptionsProps> = ({ onDelete }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                setIsModalVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () =>
            document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleModal = () => setIsModalVisible(!isModalVisible);

    return (
        <div className="ellipsis-options-container" ref={modalRef}>
            <button className="ellipsis-button" onClick={toggleModal}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </button>

            {isModalVisible && (
                <div className="options-modal">
                    <div className="options-modal-content">
                        <button
                            className="modal-button"
                            onClick={() => {
                                onDelete();
                                toggleModal();
                            }}
                        >
                            <FontAwesomeIcon icon={faTrash} /> Delete Visual
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EllipsisOptions;
