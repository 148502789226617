import React, { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { useNavigateWithinOrg } from '../utils/navigation';

const AssetDetailsBackButton: FunctionComponent = () => {
    const navigate = useNavigateWithinOrg();
    const showLibraryHandler = () => {
        navigate('/library');
    };
    return (
        <button
            className={`back-to-library-button ${isMobile && 'mobile'}`}
            onClick={showLibraryHandler}
        >
            <FontAwesomeIcon icon={faChevronLeft} />
            <p>Back to library</p>
        </button>
    );
};

export default AssetDetailsBackButton;
