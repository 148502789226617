import React, { useState } from 'react';
import StatusIndicator from './StatusIndicator';
import { useAssetContext } from '../contexts/AssetContext';
import ApprovalStatusModal from './ApprovalStatusModal';
import EllipsisOptions from './EllipsisOptions';

interface AssetDetailsTitleBarProps {
    handleDelete: () => void;
}

const AssetDetailsTitleBar: React.FC<AssetDetailsTitleBarProps> = ({
    handleDelete,
}) => {
    const {
        state: {
            reviewResult: {
                properties: { asset_name },
                approval_status,
            },
        },
    } = useAssetContext();
    const [showApprovalStatus, setShowApprovalStatus] = useState(false);

    return (
        <div className="approval-container">
            <ApprovalStatusModal
                isActive={showApprovalStatus}
                onClose={() => setShowApprovalStatus(false)}
            />
            <h2>{asset_name}</h2>
            <div className="approval-actions">
                <StatusIndicator
                    decision={approval_status}
                    isBig={true}
                    onEdit={() => setShowApprovalStatus(true)}
                />
                {/* <button className="approval-delete">
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </button> */}
                <EllipsisOptions onDelete={handleDelete} />

                {/* <AssetDetailsBackButton /> */}
            </div>
        </div>
    );
};

export default AssetDetailsTitleBar;
