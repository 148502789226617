import React, { useState } from 'react';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import SearchBar from './SearchBar';
import RuleCard from './RuleCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Outcome, RuleResult } from '../types';
import FeedbackModal from './FeedbackModal';

interface RuleChecksTableProps {
    ruleResults: RuleResult[];
}

const RuleChecksTable: React.FC<RuleChecksTableProps> = ({ ruleResults }) => {
    const [viewAll, setViewAll] = useState<boolean>(false);
    const [selectedRuleForFeedback, setSelectedRuleForFeedback] = useState<
        string | null
    >(null);
    const [filteredRules, setFilteredRules] =
        useState<RuleResult[]>(ruleResults);

    const handleSearch = (searchTerm: string) => {
        const lowercasedTerm = searchTerm.toLowerCase();
        const filtered = ruleResults.filter((checkResult) =>
            Object.values(checkResult).some(
                (value) =>
                    value !== null &&
                    value !== undefined &&
                    value.toString().toLowerCase().includes(lowercasedTerm),
            ),
        );
        setFilteredRules(filtered);
    };
    const failedRules = ruleResults.filter(
        (rule) =>
            rule.outcome === Outcome.WARNING || rule.outcome === Outcome.FAIL,
    ).length;

    // const numFailWarning = filteredRules.filter(
    //     (rule) => rule.outcome === 'Warning' || rule.outcome === 'Fail',
    // ).length;
    const numRuleDisplayed = Math.max(5, failedRules);
    const numFailWarning = 1 * 0;
    const displayedRules = viewAll
        ? filteredRules
        : filteredRules.slice(0, Math.max(numFailWarning, numRuleDisplayed));

    return (
        <>
            <FeedbackModal
                feedbackRule={selectedRuleForFeedback}
                onClose={() => setSelectedRuleForFeedback(null)}
            />
            <div style={{ paddingRight: '10px' }}>
                <div className="rules-check-controls">
                    <h3>Rule Checks</h3>
                    <SearchBar onSearch={handleSearch} searchOnEnter={false} />
                </div>
                <div className="rule-checks">
                    {displayedRules.map((rule, index) => (
                        <RuleCard
                            key={index}
                            ruleResult={rule}
                            handleFeedbackButton={(ruleId: string) => {
                                setSelectedRuleForFeedback(ruleId);
                            }}
                        />
                    ))}
                </div>
                {filteredRules.length > numRuleDisplayed && (
                    <button
                        onClick={() => {
                            setViewAll(!viewAll);
                        }}
                        className="view-more-less-button"
                    >
                        {`View ${viewAll ? 'less ' : 'all '}`}
                        <FontAwesomeIcon
                            icon={viewAll ? faAngleUp : faAngleDown}
                        />
                    </button>
                )}
            </div>
        </>
    );
};

export default RuleChecksTable;
