// src/components/organization/OrganizationList.tsx
import React from 'react';
import {
    OrganizationList as ClerkOrganizationList,
    SignOutButton,
    useOrganizationList,
} from '@clerk/clerk-react';
import { clerkSignInUpElements, clerkVariables } from '../variables/clerk';

const OrganizationList = () => {
    const { userMemberships, userInvitations } = useOrganizationList({
        userMemberships: { infinite: true },
        userInvitations: { infinite: true },
    });

    return (
        <div className="auth-container">
            {userMemberships.isLoading == false &&
                userInvitations.isLoading == false &&
                userMemberships.data &&
                userInvitations.data && (
                    <>
                        {userMemberships.data.length > 0 ||
                        userInvitations.data.length > 0 ? (
                            <ClerkOrganizationList
                                hidePersonal={true}
                                afterSelectOrganizationUrl={(org) =>
                                    `/orgs/${org.slug}`
                                }
                                appearance={{
                                    elements: clerkSignInUpElements,
                                    variables: clerkVariables,
                                }}
                            />
                        ) : (
                            <div className="no-organization-container">
                                <h1 className="no-org-title">
                                    No Organisation Access
                                </h1>
                                <div>
                                    <p className="no-org-text">
                                        You are not currently a member of any
                                        country organisation on the app.
                                    </p>
                                    <p className="no-org-text">
                                        To get access for Belgium, Luxembourg,
                                        France, or the Netherlands, please email{' '}
                                        <a
                                            href="mailto:laure.bouchet@ab-inbev.com"
                                            className="no-org-link"
                                        >
                                            Laure Bouchet
                                        </a>
                                        .
                                    </p>
                                </div>
                                <div className="sign-out-wrapper">
                                    <SignOutButton>
                                        <button className="sign-out-button">
                                            Sign Out
                                        </button>
                                    </SignOutButton>
                                </div>
                            </div>
                        )}
                    </>
                )}
        </div>
    );
};

export default OrganizationList;
