import React from 'react';
import { SignIn as SignInComponent } from '@clerk/clerk-react';
import { clerkSignInUpElements, clerkVariables } from '../variables/clerk';

const SignIn = () => {
    return (
        <div className="auth-container">
            <SignInComponent
                path="/sign-in"
                routing="path"
                signUpUrl="/sign-up"
                appearance={{
                    elements: {
                        ...clerkSignInUpElements,
                        form: 'hidden', // Hides the form
                        dividerRow: 'hidden', // Hides the "or"
                    },
                    variables: clerkVariables,
                }}
            />
        </div>
    );
};

export default SignIn;
