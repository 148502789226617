import React, { useRef, useState, ChangeEvent, DragEvent } from 'react';
import * as pdfjsLib from 'pdfjs-dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import 'pdfjs-dist/web/pdf_viewer.css';
import PdfPageSelectorModal from './PdfPageSelectorModal';
import ImageWarningModal from './ImageWarningModal';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

interface UploadBoxProps {
    setSelectedFile: (file: File | null) => void;
    selectedFile: File | null;
    setSelectedPdfPage: React.Dispatch<
        React.SetStateAction<number | undefined>
    >;
}

export interface UploadBoxHandles {
    handleUploadClick: () => void;
}

const UploadBoxAsset: React.FC<UploadBoxProps> = ({
    selectedFile,
    setSelectedFile,
    setSelectedPdfPage,
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [showPdfPageSelector, setShowPdfPageSelector] = useState(false);
    const [showImageWarning, setShowImageWarning] = useState(false);
    const [tempFile, setTempFile] = useState<File | null>(null);
    const [pdfPageCount, setPdfPageCount] = useState<number>();

    const checkImageDimensions = (file: File): Promise<boolean> => {
        return new Promise((resolve) => {
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);

            img.onload = () => {
                URL.revokeObjectURL(objectUrl);
                const maxDimension = Math.max(img.width, img.height);
                if (maxDimension < 2000) {
                    setShowImageWarning(true);
                    setTempFile(file);
                    resolve(false);
                } else {
                    resolve(true);
                }
            };

            img.src = objectUrl;
        });
    };

    const getPdfPageCount = async (file: File): Promise<number> => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = async () => {
                try {
                    const typedArray = new Uint8Array(
                        fileReader.result as ArrayBuffer,
                    );
                    const pdf = await pdfjsLib.getDocument(typedArray).promise;
                    resolve(pdf.numPages);
                } catch (error) {
                    reject(error);
                }
            };
            fileReader.onerror = () => {
                reject(new Error('Failed to read the file.'));
            };
            fileReader.readAsArrayBuffer(file);
        });
    };

    const renderPdf = async (file: File, pageNumber = 1) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
            const typedArray = new Uint8Array(fileReader.result as ArrayBuffer);
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            const page = await pdf.getPage(pageNumber);
            const scale = 1.5;
            const viewport = page.getViewport({ scale });
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            if (!context) {
                alert('Error rendering PDF');
                return;
            }

            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            await page.render(renderContext).promise;
            setPreviewUrl(canvas.toDataURL());
        };
        fileReader.readAsArrayBuffer(file);
    };

    const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            await validateFile(file);
        }
    };

    const validateFile = async (file: File) => {
        const fileType = file.name.split('.').pop()?.toLowerCase();
        if (!['pdf', 'jpg', 'jpeg', 'png'].includes(fileType || '')) {
            alert('Invalid file format. Please upload a PDF or image file.');
            return;
        }
        if (file.size > 10 * 1024 * 1024) {
            alert('File size should not exceed 10MB');
            return;
        }

        if (fileType === 'pdf') {
            setTempFile(file);
            const newPdfPageCount = await getPdfPageCount(file);
            setPdfPageCount(newPdfPageCount);
            console.log(newPdfPageCount);
            if (newPdfPageCount !== 1) {
                setShowPdfPageSelector(true);
            } else {
                await renderPdf(file);
                setTempFile(null);
                setSelectedFile(file);
            }
        } else {
            const isValidDimension = await checkImageDimensions(file);
            if (isValidDimension) {
                setSelectedFile(file);
                setPreviewUrl(URL.createObjectURL(file));
            }
        }
    };

    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(true);
    };

    const handleDragOut = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            validateFile(file);
        }
    };

    const handlePdfPageSelectorClose = (selectedPage?: number) => {
        setSelectedPdfPage(selectedPage);
        setShowPdfPageSelector(false);
        if (selectedPage && tempFile) {
            setSelectedFile(tempFile);
            renderPdf(tempFile, selectedPage);
        }
        setTempFile(null);
    };

    const handleImageWarningClose = (proceed: boolean) => {
        setShowImageWarning(false);
        if (proceed && tempFile) {
            setSelectedFile(tempFile);
            setPreviewUrl(URL.createObjectURL(tempFile));
        }
        setTempFile(null);
    };

    const triggerFileUpload = () => {
        fileInputRef.current?.click();
    };

    const handleDelete = () => {
        setSelectedFile(null);
        setPreviewUrl(null);
    };

    return (
        <>
            {selectedFile === null ? (
                <>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        accept=".pdf, .jpg, .jpeg, .png"
                    />
                    <div
                        className={`upload-box ${isDragging ? 'dragging' : ''}`}
                        onClick={triggerFileUpload}
                        onDragOver={handleDrag}
                        onDragEnter={handleDragIn}
                        onDragLeave={handleDragOut}
                        onDrop={handleDrop}
                    >
                        <FontAwesomeIcon
                            icon={faFileLines}
                            className="image-icon"
                        />
                        <div className="upload-message">
                            {'Drag your document here or '}
                            <span className="browse-text">browse files</span>
                        </div>
                        <div className="upload-filetype-message">
                            Supports: PDF, JPG, JPEG, PNG
                        </div>
                    </div>
                </>
            ) : (
                <div className="preview-box">
                    {previewUrl && (
                        <img
                            className={isMobile ? 'mobile' : ''}
                            src={previewUrl}
                            alt={selectedFile.name}
                        />
                    )}
                    <div className="info-container">
                        <div className="file-name">{selectedFile.name}</div>
                        <div className="file-size">
                            {`${
                                Math.round(
                                    (selectedFile.size / (1024 * 1024) +
                                        Number.EPSILON) *
                                        100,
                                ) / 100
                            } MB`}
                        </div>
                        <div className="approved-container">
                            <div className="approved-box">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="approved-icon"
                                ></FontAwesomeIcon>
                            </div>
                            <div className="approved-text">{`Valid${
                                !isMobile ? ' document' : ''
                            }`}</div>
                        </div>
                    </div>
                    <div className="delete-box" onClick={handleDelete}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="delete-icon"
                        ></FontAwesomeIcon>
                    </div>
                </div>
            )}

            {showPdfPageSelector && pdfPageCount && (
                <PdfPageSelectorModal
                    isOpen={showPdfPageSelector}
                    maxPages={pdfPageCount}
                    onClose={handlePdfPageSelectorClose}
                />
            )}

            {showImageWarning && (
                <ImageWarningModal
                    isOpen={showImageWarning}
                    onClose={handleImageWarningClose}
                />
            )}
        </>
    );
};

export default UploadBoxAsset;
