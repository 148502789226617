import React, { useEffect, useRef, useState } from 'react';
import { useGesture } from '@use-gesture/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus,
    faMinus,
    faRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';

import { BoundingRect, AssetReviewResult, Outcome } from '../types';
import { useAssetContext } from '../contexts/AssetContext';
import VisualEvidenceItem from './VisualEvidenceItem';

const getVisualEvidence = (
    item: AssetReviewResult,
    focussedRule: string | null,
    hoveredRule: string | null,
    imageSize: { width: number; height: number },
): {
    boundingRects: BoundingRect[];
    colour: { r: number; g: number; b: number };
    text: { title: string; description: string[] };
    isRuleHovered: boolean;
}[] => {
    const visualEvidence: {
        boundingRects: BoundingRect[];
        colour: { r: number; g: number; b: number };
        text: { title: string; description: string[] };
        isRuleHovered: boolean;
    }[] = [];

    // Iterate through each RuleResult
    for (const result of item.rule_results) {
        result.evidence_items.forEach((evidence) => {
            // if (
            //     ((focussedRule === null ||
            //         (focussedRule !== null && focussedRule !== result.name)) &&
            //         hoveredRule === null) ||
            //     (hoveredRule !== null && hoveredRule !== result.name)
            // ) {
            //     return;
            // }
            if (focussedRule === result.name || hoveredRule === result.name) {
                visualEvidence.push({
                    boundingRects: evidence.bounding_rects.map((rect) => ({
                        x_min: rect.x_min * imageSize.width,
                        y_min: rect.y_min * imageSize.height,
                        x_max: rect.x_max * imageSize.width,
                        y_max: rect.y_max * imageSize.height,
                    })),
                    colour:
                        evidence.outcome === Outcome.FAIL
                            ? { r: 255, g: 0, b: 0 }
                            : evidence.outcome === Outcome.WARNING
                              ? { r: 238, g: 210, b: 2 }
                              : { r: 92, g: 184, b: 92 },

                    text: {
                        title: result.display_name,
                        description: evidence.notes
                            ? evidence.notes
                            : Array.isArray(result.summary.text)
                              ? result.summary.text
                              : [result.summary.text],
                    },
                    isRuleHovered: hoveredRule === result.name,
                });
            }
        });
    }

    return visualEvidence;
};

export const getCombinedBoundingRect = (
    boundingRects: BoundingRect[],
): BoundingRect => {
    if (boundingRects.length === 0) {
        throw new Error('BoundingRect array is empty');
    }

    let combinedXMin = Infinity;
    let combinedXMax = -Infinity;
    let combinedYMin = Infinity;
    let combinedYMax = -Infinity;

    for (const rect of boundingRects) {
        combinedXMin = Math.min(combinedXMin, rect.x_min);
        combinedXMax = Math.max(combinedXMax, rect.x_max);
        combinedYMin = Math.min(combinedYMin, rect.y_min);
        combinedYMax = Math.max(combinedYMax, rect.y_max);
    }

    return {
        x_min: combinedXMin,
        x_max: combinedXMax,
        y_min: combinedYMin,
        y_max: combinedYMax,
    };
};

const ImageCanvas: React.FC = () => {
    const viewerContainerRef = useRef<HTMLDivElement | null>(null);
    const imageRef = useRef<HTMLImageElement | null>(null);
    const lastPosition = useRef({ x: 0, y: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [showBoundingBoxes, setShowBoundingBoxes] = useState(true);
    const minScale = 0.5;
    const maxScale = 20;
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

    const {
        state: { imageUrl, reviewResult, focussedRule, hoveredRule },
    } = useAssetContext();

    const visualEvidence = getVisualEvidence(
        reviewResult,
        focussedRule,
        hoveredRule,
        imageSize,
    );
    console.log('visualEvidence', visualEvidence);

    // useEffect(() => {
    //     const handlePopState = (event: PopStateEvent): void => {
    //         event.preventDefault();
    //         window.history.pushState(null, '', window.location.href);
    //     };

    //     window.history.pushState(null, '', window.location.href);
    //     window.addEventListener('popstate', handlePopState);

    //     return (): void => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, []);

    // const focusOnBoundingRect = (boundingRect: BoundingRect) => {
    //     if (viewerContainerRef.current) {
    //         const { width, height } =
    //             viewerContainerRef.current.getBoundingClientRect();
    //         const boundingRectWidth = boundingRect.x_max - boundingRect.x_min;
    //         const boundingRectHeight = boundingRect.y_max - boundingRect.y_min;

    //         const focusX =
    //             (boundingRect.x_max + boundingRect.x_min) / 2 -
    //             imageSize.width / 2;
    //         const focusY =
    //             (boundingRect.y_max + boundingRect.y_min) / 2 -
    //             imageSize.height / 2;

    //         if (rotation === 90 || rotation === 270) {
    //             const newScale = Math.min(
    //                 Math.min(
    //                     width / boundingRectHeight,
    //                     height / boundingRectWidth,
    //                 ) / 1.1,
    //                 maxScale,
    //             );
    //             const newX = -focusX * newScale;
    //             const newY = -focusY * newScale;

    //             if (rotation === 90) {
    //                 lastPosition.current = { x: -newY, y: newX };
    //                 setPosition({ x: -newY, y: newX });
    //             } else if (rotation === 270) {
    //                 lastPosition.current = { x: newY, y: -newX };
    //                 setPosition({ x: newY, y: -newX });
    //             }
    //             setScale(newScale);
    //         } else {
    //             const newScale = Math.min(
    //                 Math.min(
    //                     width / boundingRectWidth,
    //                     height / boundingRectHeight,
    //                 ) / 1.1,
    //                 maxScale,
    //             );

    //             const newX = -focusX * newScale;
    //             const newY = -focusY * newScale;

    //             if (rotation === 0) {
    //                 lastPosition.current = { x: newX, y: newY };
    //                 setPosition({ x: newX, y: newY });
    //             } else if (rotation === 180) {
    //                 lastPosition.current = { x: -newX, y: -newY };
    //                 setPosition({ x: -newX, y: -newY });
    //             }
    //             setScale(newScale);
    //         }
    //     }
    // };

    // const resetZoomAndTranslation = () => {
    //     if (viewerContainerRef.current && imageRef.current) {
    //         lastPosition.current = { x: 0, y: 0 };
    //         setScale(1);
    //         setPosition({ x: 0, y: 0 });
    //     }
    // };

    useEffect(() => {
        setShowBoundingBoxes(true);
        // if (focussedRule !== null) {
        //     const focussedRect = getCombinedBoundingRect(
        //         visualEvidence.map((evid) => evid.boundingRects).flat(),
        //     );
        //     focusOnBoundingRect(focussedRect);
        // } else {
        //     resetZoomAndTranslation();
        // }
    }, [focussedRule]);

    const updateImageSize = () => {
        if (imageRef.current) {
            setImageSize({
                width: imageRef.current.width,
                height: imageRef.current.height,
            });
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateImageSize);
        updateImageSize();

        return () => {
            window.removeEventListener('resize', updateImageSize);
        };
    }, [imageRef.current]);

    const bind = useGesture(
        {
            onWheel: ({ event, delta: [dx, dy] }) => {
                event.preventDefault();
                lastPosition.current = {
                    x: lastPosition.current.x - dx,
                    y: lastPosition.current.y - dy,
                };
                setPosition({
                    x: lastPosition.current.x,
                    y: lastPosition.current.y,
                });
            },
            onDrag: ({
                event,
                down,
                movement: [mx, my],
                memo = [lastPosition.current.x, lastPosition.current.y],
            }) => {
                event.preventDefault();
                if (!down) {
                    lastPosition.current = { x: mx + memo[0], y: my + memo[1] };
                }
                setPosition({
                    x: down ? mx + memo[0] : lastPosition.current.x,
                    y: down ? my + memo[1] : lastPosition.current.y,
                });
                return memo;
            },
            onPinch: ({ offset: [d], origin }) => {
                const newScale = Math.min(Math.max(d, minScale), maxScale);
                if (viewerContainerRef.current) {
                    const { top, left, width, height } =
                        viewerContainerRef.current.getBoundingClientRect();
                    const mouseX = origin[0] - left - width / 2;
                    const mouseY = origin[1] - top - height / 2;

                    const scaleFactor = newScale / scale;
                    const newX = mouseX - (mouseX - position.x) * scaleFactor;
                    const newY = mouseY - (mouseY - position.y) * scaleFactor;

                    lastPosition.current = { x: newX, y: newY };
                    setPosition({ x: newX, y: newY });
                    setScale(newScale);
                }
            },
        },
        { pinch: { pinchOnWheel: true, threshold: 0 } },
    );

    useEffect(() => {
        const viewerContainer = viewerContainerRef.current;
        if (!viewerContainer) return;

        const handleWheel = (event: WheelEvent) => {
            // if (event.ctrlKey) {
            event.preventDefault();
            // }
        };

        viewerContainer.addEventListener('wheel', handleWheel);

        return () => {
            viewerContainer.removeEventListener('wheel', handleWheel);
        };
    }, []);

    // Button handlers
    const handleZoomIn = () => {
        const newScale = Math.min(scale * 1.4, maxScale);
        const scaleFactor = newScale / scale;

        const newX = position.x * scaleFactor;
        const newY = position.y * scaleFactor;

        lastPosition.current = { x: newX, y: newY };
        setPosition({ x: newX, y: newY });

        setScale(newScale);
    };

    const handleZoomOut = () => {
        const newScale = Math.max(scale / 1.4, minScale);
        const scaleFactor = newScale / scale;

        const newX = position.x * scaleFactor;
        const newY = position.y * scaleFactor;

        lastPosition.current = { x: newX, y: newY };
        setPosition({ x: newX, y: newY });

        setScale(newScale);
    };

    const handleRotate = () => {
        const newRotation = rotation + 90;
        setRotation(newRotation >= 360 ? newRotation - 360 : newRotation);
        lastPosition.current = { x: -position.y, y: position.x };
        setPosition({ x: -position.y, y: position.x });
    };

    // const handleBBVisibility = () => {
    //     if (showBoundingBoxes) {
    //         setShowBoundingBoxes(false);
    //         // setFocussedRule(null);
    //     } else {
    //         setShowBoundingBoxes(true);
    //     }
    // };

    return (
        <div
            className="viewer-container-wrapper"
            style={{ position: 'relative' }}
        >
            <div
                ref={viewerContainerRef}
                className="viewer-container"
                {...bind()}
                style={{
                    msTouchAction: 'none',
                    overflow: 'hidden',
                    position: 'relative',
                    touchAction: 'none',
                    cursor: 'grab',
                    height: 'calc(100vh - 110px - 30px)',
                    width: '100%',
                    overscrollBehaviorX: 'none',
                }}
            >
                <div
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px) scale(${scale}) rotate(${rotation}deg)`,
                        transformOrigin: 'center center',
                        touchAction: 'none',
                    }}
                >
                    <img
                        src={imageUrl}
                        alt="Interactive Canvas"
                        ref={imageRef}
                        style={{
                            // width: '100%',
                            height: 'auto',
                            maxWidth: '100%',
                            maxHeight: 'calc(100vh - 110px)',
                        }}
                        onLoad={() => updateImageSize()}
                    />
                    {showBoundingBoxes &&
                        visualEvidence.map((evid, index) => {
                            // const combinedBoundingRect =
                            //     getCombinedBoundingRect(evid.boundingRects);
                            return (
                                <VisualEvidenceItem key={index} evid={evid} />
                            );
                        })}{' '}
                </div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 10,
                    zIndex: 10,
                }}
            >
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Zoom in
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleZoomIn} style={buttonStyle}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </Tooltip>
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Zoom out
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleZoomOut} style={buttonStyle}>
                        <FontAwesomeIcon icon={faMinus} />
                    </button>
                </Tooltip>
            </div>
            <div
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    zIndex: 10,
                }}
            >
                <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            Rotate
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleRotate} style={buttonStyle}>
                        <FontAwesomeIcon icon={faRotateRight} />
                    </button>
                </Tooltip>
                {/* <Tooltip
                    title={
                        <p
                            style={{
                                margin: '0px',
                                fontSize: '1.4em',
                            }}
                        >
                            {showBoundingBoxes
                                ? 'Hide bounding boxes'
                                : 'Show bounding boxes'}
                        </p>
                    }
                    placement="left"
                >
                    <button onClick={handleBBVisibility} style={buttonStyle}>
                        <FontAwesomeIcon
                            icon={showBoundingBoxes ? faEye : faEyeSlash}
                        />
                    </button>
                </Tooltip> */}
            </div>
        </div>
    );
};

const buttonStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    border: 'none',
    borderRadius: '50%',
    color: 'white',
    cursor: 'pointer',
    fontSize: '1.2em',
    height: '25px',
    width: '25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
};

export default ImageCanvas;
