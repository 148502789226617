import { useState } from 'react';
import { LibraryContextType } from '../contexts/LibraryContext';
import {
    AppliedFilter,
    OutgoingFilter,
    SortOption,
    BaseAssetReviewResultWithUserDetails,
} from '../types';

export const sortOptions: SortOption[] = [
    {
        label: 'Newest first',
        variable: 'date',
        sortOrder: 'descending',
    },
    {
        label: 'Oldest first',
        variable: 'date',
        sortOrder: 'ascending',
    },
];

export function useDefaultLibraryContext(): LibraryContextType {
    const [libraryAssets, setLibraryAssets] = useState<
        BaseAssetReviewResultWithUserDetails[]
    >([]);
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilter[]>([]);
    const [searchString, setSearchString] = useState('');
    const [numItems, setNumItems] = useState<number>(60);
    const [prevPage, setPrevPage] = useState<number | null>(null);
    const [nextPage, setNextPage] = useState<number | null>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [filterCounts, setFilterCounts] = useState<OutgoingFilter[]>([]);
    const [imageUrls, setImageUrls] = useState<{ [id: string]: string }>({});
    const [appliedSortOption, setAppliedSortOption] = useState<SortOption>(
        sortOptions[0],
    );
    const [libraryIsLoading, setLibraryIsLoading] = useState<boolean>(true);

    const state = {
        currentPage,
        totalPages,
        prevPage,
        nextPage,
        numItems,
        appliedSortOption,
        sortOptions,
        appliedFilters,
        filterCounts,
        libraryAssets,
        imageUrls,
        searchString,
        libraryIsLoading,
    };

    const setters = {
        setCurrentPage,
        setTotalPages,
        setPrevPage,
        setNextPage,
        setNumItems,
        setAppliedSortOption,
        setAppliedFilters,
        setFilterCounts,
        setLibraryAssets,
        setImageUrls,
        setSearchString,
        setLibraryIsLoading,
    };

    return { state, setters };
}
