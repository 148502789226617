import React, { useState, useRef, useEffect } from 'react';

interface NewCommentProps {
    onSubmit: (comment: string) => void;
    onCancel: () => void;
    avatarUrl: string;
}

const NewComment: React.FC<NewCommentProps> = ({
    onSubmit,
    onCancel,
    avatarUrl,
}) => {
    const [comment, setComment] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    // Focus the textarea on component render
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.focus();
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, []);
    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);

        // Auto-resize the textarea
        if (textareaRef.current) {
            console.log(textareaRef.current.scrollHeight);
            textareaRef.current.style.height = 'auto'; // Reset height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            // Prevent default Enter behavior (new line) if Shift is not pressed
            e.preventDefault();
            if (comment.trim()) {
                onSubmit(comment);
                setComment(''); // Clear the textarea after submission
            }
        }
        if (e.key === 'Escape') {
            onCancel();
        }
    };

    return (
        <div className="activity-item">
            <div className="left-side">
                <div className="line"></div>
                <img className="activity-avatar" src={avatarUrl} alt="Avatar" />
            </div>
            <div className="right-side">
                <div className="text-field">
                    <textarea
                        ref={textareaRef}
                        placeholder="Add comment..."
                        value={comment}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        rows={1}
                        style={{
                            resize: 'none',
                            overflow: 'hidden',
                            backgroundColor: 'transparent', // Transparent background
                            border: 'none', // Remove border
                            outline: 'none', // Remove focus outline
                            width: '100%', // Full width
                            fontSize: '1.1em',
                        }}
                    />
                    <div className="button-container">
                        <button onClick={onCancel} className="cancel">
                            Cancel
                        </button>
                        {/* <button onClick={onCancel}>
                            <FontAwesomeIcon icon={faX} />
                        </button> */}
                        <button
                            onClick={() => onSubmit(comment)}
                            className="submit"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewComment;
