import React from 'react';
import { RuleResult, Outcome } from '../types';
import {
    faExclamation,
    faEye as faEyeSolid,
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@mui/material/Tooltip';
import { faEye, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssetContext } from '../contexts/AssetContext';

interface RuleCardProps {
    ruleResult: RuleResult;
    handleFeedbackButton: (ruleId: string) => void;
}

const colourMap = {
    [Outcome.FAIL]: 'red',
    [Outcome.WARNING]: '#E0B80E',
    [Outcome.PASS]: '#389E0D',
};

const RuleCard: React.FC<RuleCardProps> = ({
    ruleResult,
    handleFeedbackButton,
}) => {
    const {
        setters: { setFocussedRule, setHoveredRule },
        state: { focussedRule, hoveredRule },
    } = useAssetContext();
    const focussed = focussedRule === ruleResult.name;
    const hovered = hoveredRule === ruleResult.name;

    const summaryArray = () => {
        if (Array.isArray(ruleResult.summary.text)) {
            return ruleResult.summary.text;
        } else {
            return [ruleResult.summary.text];
        }
    };
    function canFocus() {
        return ruleResult.evidence_items.length > 0;
    }
    const handleFocus = () => {
        if (canFocus()) {
            if (focussed) {
                setFocussedRule(null);
            } else {
                setFocussedRule(ruleResult.name);
            }
        }
    };
    return (
        <div
            className={`rules-check-card ${focussed ? 'is-focussed' : ''} ${canFocus() ? 'can-focus' : ''}`}
            onMouseEnter={() => setHoveredRule(ruleResult.name)}
            onMouseLeave={() => setHoveredRule(null)}
            onClick={() => handleFocus()}
        >
            <div
                className="decision-colour-box"
                style={{
                    backgroundColor: colourMap[ruleResult.outcome],
                }}
            />
            <div className="content-container">
                <div className="upper-container">
                    <div className="text-container">
                        <p
                            className="check-name"
                            style={{ marginRight: '5px' }}
                        >
                            {ruleResult.display_name}
                            {ruleResult.description && (
                                <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            {ruleResult.description}
                                        </p>
                                    }
                                    placement="top"
                                    arrow
                                >
                                    <FontAwesomeIcon icon={faQuestionCircle} />
                                </Tooltip>
                            )}
                        </p>
                        {ruleResult.outcome_history.length === 0 ? (
                            <>
                                {summaryArray().map((text, index) => (
                                    <p key={index} className="check-summary">
                                        {text}
                                    </p>
                                ))}
                                {ruleResult.summary.bullets && (
                                    <ul className="check-summary-bullets">
                                        {ruleResult.summary.bullets.map(
                                            (bullet, i) => (
                                                <li key={i}>{bullet}</li>
                                            ),
                                        )}
                                    </ul>
                                )}
                            </>
                        ) : (
                            <p>
                                <span className="highlighted">
                                    User Feedback:{' '}
                                </span>
                                {
                                    ruleResult.outcome_history[
                                        ruleResult.outcome_history.length - 1
                                    ].reason
                                }
                            </p>
                        )}
                    </div>
                    <div className="button-container">
                        {hovered && (
                            <>
                                <Tooltip
                                    title={
                                        <p
                                            style={{
                                                margin: '0px',
                                                fontSize: '1.4em',
                                            }}
                                        >
                                            Feedback
                                        </p>
                                    }
                                    placement="top"
                                >
                                    <button
                                        onClick={() =>
                                            handleFeedbackButton(
                                                ruleResult.name,
                                            )
                                        }
                                    >
                                        <FontAwesomeIcon icon={faExclamation} />
                                    </button>
                                </Tooltip>
                            </>
                        )}
                        {canFocus() && (
                            <Tooltip
                                title={
                                    <p
                                        style={{
                                            margin: '0px',
                                            fontSize: '1.4em',
                                        }}
                                    >
                                        {focussed ? 'Unfocus' : 'Lock Focus'}
                                    </p>
                                }
                                placement="top"
                                open={hovered}
                            >
                                <button
                                    onClick={() => handleFocus()}
                                    className={focussed ? 'focussed' : ''}
                                >
                                    <FontAwesomeIcon
                                        icon={focussed ? faEyeSolid : faEye}
                                    />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RuleCard;
