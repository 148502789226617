import { createContext, useContext } from 'react';
import {
    ApprovalStatus,
    AssetReviewResult,
    CommentActivity,
    Outcome,
    StatusChangeActivity,
    UploadActivity,
    UserFeedbackActivity,
} from '../types';

// Your existing state interface
export interface AssetState {
    reviewResult: AssetReviewResult;
    imageUrl: string;
    activities: (
        | StatusChangeActivity
        | CommentActivity
        | UploadActivity
        | UserFeedbackActivity
    )[];
    focussedRule: string | null;
    hoveredRule: string | null;
}

export interface AssetHandlers {
    handleApprovalStatusChange: (
        assetId: string,
        newStatus: ApprovalStatus,
        reason?: string,
    ) => Promise<void>;
    handleCommentSubmission: (
        assetId: string,
        comment: string,
    ) => Promise<void>;
    handleFeedbackSubmission: (
        assetId: string,
        ruleId: string,
        newOutcome: Outcome,
        comment: string,
    ) => Promise<void>;
}

// New interface for setters
export interface AssetSetters {
    setFocussedRule: React.Dispatch<React.SetStateAction<string | null>>;
    setHoveredRule: React.Dispatch<React.SetStateAction<string | null>>;
}

// Update the context to include both state and setters
export interface AssetContextType {
    state: AssetState;
    setters: AssetSetters;
    handlers: AssetHandlers;
}

export const AssetContext = createContext<AssetContextType | null>(null);

export function useAssetContext() {
    const assetContext = useContext(AssetContext);

    if (assetContext === null) {
        throw new Error('useAssetContext must be used with a AssetContext');
    }

    return assetContext;
}
