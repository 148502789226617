import React, { useState } from 'react';
import ModalBase from './ModalBase';
import { Outcome } from '../types';
import { useAssetContext } from '../contexts/AssetContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faXmark,
    faTriangleExclamation,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';

interface FeedbackModalProps {
    feedbackRule: string | null;
    onClose: () => void;
}

interface StatusConfig {
    icon: typeof faCheck;
    description: string;
    textColor: string;
}

// Default grey for inactive state
const defaultTextColor = '#595959';

const outcomeConfigs: Record<Outcome, StatusConfig> = {
    [Outcome.FAIL]: {
        icon: faXmark,
        description: 'The rule was clearly violated',
        textColor: '#D52214', // Base red text
    },
    [Outcome.WARNING]: {
        icon: faTriangleExclamation,
        description: 'Requires additional review from moderator',
        textColor: '#E0B80E', // Base orange text
    },
    [Outcome.PASS]: {
        icon: faCheck,
        description: 'Asset has been approved for use',
        textColor: '#389E0D', // Base green text
    },
};

const hexToRGB = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return { r, g, b };
};

const deriveColors = (textColor: string) => {
    const { r, g, b } = hexToRGB(textColor);

    return {
        text: textColor,
        border: `rgba(${r}, ${g}, ${b}, 0.6)`,
        background: `rgba(${r}, ${g}, ${b}, 0.1)`,
    };
};

const getStyleObject = (
    outcome: Outcome,
    isCurrent: boolean,
    isHovered: boolean,
) => {
    const config = outcomeConfigs[outcome];
    const colors =
        isCurrent || isHovered
            ? deriveColors(config.textColor)
            : {
                  text: defaultTextColor,
                  border: 'rgba(89, 89, 89, 0.3)', // derived from defaultTextColor
                  background: 'transparent',
              };

    return {
        border: `1px solid ${colors.border}`,
        color: colors.text,
        backgroundColor: colors.background,
        opacity: isCurrent ? 0.85 : 1,
        transition: 'all 0.3s ease',
        cursor: isCurrent ? 'default' : 'pointer',
    };
};

const FeedbackModal: React.FC<FeedbackModalProps> = ({
    onClose,
    feedbackRule,
}) => {
    const {
        state: {
            reviewResult: { id, rule_results },
        },
        handlers: { handleFeedbackSubmission },
    } = useAssetContext();

    const currentRuleResult = rule_results.find(
        (rule) => rule.name === feedbackRule,
    );
    if (!currentRuleResult) return null;
    const currentOutcome = currentRuleResult.outcome;

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [selectedOutcome, setSelectedOutcome] = useState<Outcome | null>(
        currentOutcome,
    );
    const [reason, setReason] = useState('');

    const handleChange = (newOutcome: Outcome) => {
        // if (currentOutcome === newOutcome) return;
        setSelectedOutcome(newOutcome);
    };

    const submitFeedback = () => {
        if (!selectedOutcome) return;
        handleFeedbackSubmission(
            id,
            currentRuleResult.name,
            selectedOutcome,
            reason,
        );
        setReason('');
        onClose();
    };
    const summaryArray = () => {
        if (Array.isArray(currentRuleResult.summary.text)) {
            return currentRuleResult.summary.text;
        } else {
            return [currentRuleResult.summary.text];
        }
    };

    const renderOutcomeButton = (outcome: Outcome, index: number) => {
        const config = outcomeConfigs[outcome];
        const isHovered = hoveredIndex === index;
        const isSelected = outcome === selectedOutcome;
        const colors =
            isSelected || isHovered
                ? deriveColors(config.textColor)
                : {
                      text: defaultTextColor,
                      border: 'rgba(89, 89, 89, 0.3)',
                      background: 'transparent',
                  };

        return (
            <button
                className="outcome-button"
                id={index.toString()}
                disabled={isSelected}
                style={getStyleObject(outcome, isSelected, isHovered)}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => handleChange(outcome)}
            >
                <div className="upper-container">
                    <div className="approval-icon">
                        <FontAwesomeIcon
                            icon={config.icon}
                            style={{
                                color: colors.text,
                                transition: 'color 0.3s ease',
                            }}
                        />
                    </div>
                    <div className="text">
                        <p
                            className="label"
                            style={{
                                color: colors.text,
                                transition: 'color 0.3s ease',
                            }}
                        >
                            {outcome}
                        </p>
                    </div>
                </div>
                {/* <p
                    className="description"
                    style={{
                        color: colors.text,
                        transition: 'color 0.3s ease',
                    }}
                >
                    {config.description}
                </p> */}
            </button>
        );
    };

    const allOptions = [Outcome.FAIL, Outcome.WARNING, Outcome.PASS];

    return feedbackRule ? (
        <ModalBase onClose={onClose} width="500px">
            <div className="feedback-modal-content">
                <h3>Submit User Feedback</h3>
                <table className="rule-info-table">
                    <tbody>
                        <tr>
                            <td>Rule:</td>
                            <td>
                                <strong>
                                    {currentRuleResult.display_name}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td>Description:</td>
                            <td>{currentRuleResult.description}</td>
                        </tr>
                        {/* <tr>
                            <td>Current Outcome:</td>
                            <td>{currentOutcome}</td>
                        </tr> */}
                        <tr>
                            <td>Model Reasoning:</td>
                            <td>
                                {summaryArray().map((text, index) => (
                                    <p key={index} className="check-summary">
                                        {text}
                                    </p>
                                ))}
                                {currentRuleResult.summary.bullets && (
                                    <ul className="check-summary-bullets">
                                        {currentRuleResult.summary.bullets.map(
                                            (bullet, i) => (
                                                <li key={i}>{bullet}</li>
                                            ),
                                        )}
                                    </ul>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="outcome-selector">
                    {allOptions.map((option, i) =>
                        renderOutcomeButton(option, i),
                    )}
                </div>
                {selectedOutcome !== currentOutcome && (
                    <>
                        <textarea
                            placeholder="Provide a reason (required)"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        />
                        <p className="feedback-note">
                            <strong>Note</strong>: This feedback is used to
                            retrain the underlying models. Please submit
                            feedback only if something is clearly not working,
                            not for cases where you intentionally deviate from
                            the standard ruling.
                        </p>

                        <div className="button-row">
                            <button
                                className="btn-primary"
                                onClick={submitFeedback}
                                disabled={!reason.trim()}
                            >
                                Submit
                            </button>
                        </div>
                    </>
                )}
            </div>
        </ModalBase>
    ) : null;
};

export default FeedbackModal;
