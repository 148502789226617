import { createContext, useContext } from 'react';
import {
    AppliedFilter,
    OutgoingFilter,
    SortOption,
    BaseAssetReviewResultWithUserDetails,
} from '../types';

// Your existing state interface
export interface LibraryState {
    currentPage: number;
    totalPages: number;
    prevPage: number | null;
    nextPage: number | null;
    numItems: number;
    appliedSortOption: SortOption;
    appliedFilters: AppliedFilter[];
    filterCounts: OutgoingFilter[];
    libraryAssets: BaseAssetReviewResultWithUserDetails[];
    imageUrls: { [id: string]: string };
    searchString: string;
    libraryIsLoading: boolean;
}

// New interface for setters
export interface LibrarySetters {
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setTotalPages: React.Dispatch<React.SetStateAction<number>>;
    setPrevPage: React.Dispatch<React.SetStateAction<number | null>>;
    setNextPage: React.Dispatch<React.SetStateAction<number | null>>;
    setNumItems: React.Dispatch<React.SetStateAction<number>>;
    setAppliedSortOption: React.Dispatch<React.SetStateAction<SortOption>>;
    setAppliedFilters: React.Dispatch<React.SetStateAction<AppliedFilter[]>>;
    setFilterCounts: React.Dispatch<React.SetStateAction<OutgoingFilter[]>>;
    setLibraryAssets: React.Dispatch<
        React.SetStateAction<BaseAssetReviewResultWithUserDetails[]>
    >;
    setImageUrls: React.Dispatch<
        React.SetStateAction<{ [id: string]: string }>
    >;
    setSearchString: React.Dispatch<React.SetStateAction<string>>;
    setLibraryIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

// Update the context to include both state and setters
export interface LibraryContextType {
    state: LibraryState;
    setters: LibrarySetters;
}

export const LibraryContext = createContext<LibraryContextType | null>(null);

export function useLibraryContext() {
    const libraryContext = useContext(LibraryContext);

    if (libraryContext === null) {
        throw new Error('useLibraryContext must be used with a LibraryContext');
    }

    return libraryContext;
}
