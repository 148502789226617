import React from 'react';
import MainApp from './pages/MainApp';
import './App.scss';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useDefaultLibraryContext } from './hooks/useDefaultLibraryContext';
import { LibraryContext } from './contexts/LibraryContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import { UploadStateProvider } from './contexts/UploadContext';
import OrganizationList from './pages/OrganizationList';
import { syncActiveOrganizationFromURLToSession } from './utils/syncActiveOrganizationFromURLToSession';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import NavigateWithinOrg from './utils/navigation';
import { ModalProvider } from './contexts/ModalContext';

const App = () => {
    const defaultLibraryContext = useDefaultLibraryContext();

    const queryClient = new QueryClient();
    syncActiveOrganizationFromURLToSession();

    return (
        <QueryClientProvider client={queryClient}>
            <SignedIn>
                <Routes>
                    <Route
                        path="/orgs/:orgSlug/*"
                        element={
                            <>
                                <LibraryContext.Provider
                                    value={defaultLibraryContext}
                                >
                                    <ModalProvider>
                                        <UploadStateProvider>
                                            <MainApp />
                                        </UploadStateProvider>
                                    </ModalProvider>
                                </LibraryContext.Provider>
                            </>
                        }
                    />
                    <Route
                        path="/orgs"
                        element={
                            <>
                                <OrganizationList />
                            </>
                        }
                    />
                    <Route path="/sign-in/*" element={<SignIn />} />
                    <Route path="/sign-up/*" element={<SignUp />} />
                    <Route path="/" element={<NavigateWithinOrg to={'/'} />} />
                </Routes>
            </SignedIn>
            <SignedOut>
                <Routes>
                    <Route path="/sign-in/*" element={<SignIn />} />
                    <Route path="/sign-up/*" element={<SignUp />} />
                    <Route path="*" element={<Navigate to={'/sign-in'} />} />
                </Routes>
            </SignedOut>
        </QueryClientProvider>
    );
};

export default App;
