import React from 'react';
import ModalBase from './ModalBase';

interface PdfPageSelectorModalProps {
    isOpen: boolean;
    maxPages: number;
    onClose: (pageNumber?: number) => void;
}

const PdfPageSelectorModal: React.FC<PdfPageSelectorModalProps> = ({
    isOpen,
    onClose,
    maxPages,
}) => {
    const [pageNumber, setPageNumber] = React.useState<string>('1');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // Only allow numbers
        if (/^\d*$/.test(value)) {
            const numValue = parseInt(value || '1');
            if (numValue >= 1 && numValue <= maxPages) {
                setPageNumber(value);
            }
        }
    };

    const handleConfirm = () => {
        const num = parseInt(pageNumber);
        if (num >= 1 && num <= maxPages) {
            onClose(num);
        }
    };

    return isOpen ? (
        <ModalBase onClose={onClose} width="200px" hideXButton>
            <div className="pdf-page-selector-content">
                <h2>Select Page</h2>
                <div className="selector-row">
                    <input
                        type="number"
                        min="1"
                        max={maxPages}
                        value={pageNumber}
                        onChange={handleChange}
                        className="page-input"
                    />
                    <p>(max. {maxPages})</p>
                </div>
                <div className="button-row">
                    <button className="btn-secondary" onClick={() => onClose()}>
                        Cancel
                    </button>
                    <button
                        className="btn-primary"
                        onClick={handleConfirm}
                        disabled={
                            !pageNumber ||
                            parseInt(pageNumber) < 1 ||
                            parseInt(pageNumber) > maxPages
                        }
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default PdfPageSelectorModal;
