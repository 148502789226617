import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { BoundingRect } from '../types';
import { getCombinedBoundingRect } from './ImageCanvas';

interface VisualEvidenceItemProps {
    evid: {
        boundingRects: BoundingRect[];
        text: {
            title: string;
            description: string[];
        };
        colour: { r: number; g: number; b: number };
        isRuleHovered: boolean;
    };
}

const VisualEvidenceItem: React.FC<VisualEvidenceItemProps> = ({ evid }) => {
    const [hoveredRect, setHoveredRect] = useState<boolean>(false);

    const combinedBoundingRect = getCombinedBoundingRect(evid.boundingRects);

    return (
        <>
            <Tooltip
                open={hoveredRect} // Control tooltip visibility
                title={
                    <>
                        <strong style={{ margin: '0px', fontSize: '1.4em' }}>
                            {evid.text.title}
                        </strong>
                        {evid.text.description.map((text, index) => (
                            <p key={index}>{text}</p>
                        ))}
                    </>
                }
            >
                <div
                    style={{
                        position: 'absolute',
                        left: `${combinedBoundingRect.x_min}px`,
                        top: `${combinedBoundingRect.y_min}px`,
                        width: `${combinedBoundingRect.x_max - combinedBoundingRect.x_min}px`,
                        height: `${combinedBoundingRect.y_max - combinedBoundingRect.y_min}px`,
                        pointerEvents: 'none', // Allow mouse events to pass through
                    }}
                />
            </Tooltip>
            {evid.boundingRects.map((rect, index) => (
                <div
                    key={index}
                    style={{
                        position: 'absolute',
                        left: `${rect.x_min}px`,
                        top: `${rect.y_min}px`,
                        width: `${rect.x_max - rect.x_min}px`,
                        height: `${rect.y_max - rect.y_min}px`,
                        background: `rgba(${evid.colour.r}, ${evid.colour.g}, ${evid.colour.b}, 0.5)`,
                        outline:
                            hoveredRect || evid.isRuleHovered
                                ? `2px solid rgb(${evid.colour.r}, ${evid.colour.g}, ${evid.colour.b})`
                                : 'none', // Border for individual rects
                        pointerEvents: 'auto', // Ensure rects capture mouse events
                    }}
                    onMouseEnter={() => setHoveredRect(true)}
                    onMouseLeave={() => setHoveredRect(false)}
                />
            ))}
        </>
    );
};

export default VisualEvidenceItem;
