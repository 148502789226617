import React from 'react';
import ModalBase from './ModalBase';

interface ImageWarningModalProps {
    isOpen: boolean;
    onClose: (proceed: boolean) => void;
}

const ImageWarningModal: React.FC<ImageWarningModalProps> = ({
    isOpen,
    onClose,
}) => {
    return isOpen ? (
        <ModalBase onClose={() => onClose(false)} hideXButton>
            <div className="image-warning-content">
                <h2 className="modal-title">Low Resolution Warning</h2>
                <p className="modal-description">
                    The image resolution is less than 2000px on its longest
                    side. This might result in insufficient quality. We
                    recommend uploading a PDF document instead. Do you want to
                    proceed anyway?
                </p>

                <div className="button-row">
                    <button
                        className="btn-secondary"
                        onClick={() => onClose(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-primary"
                        onClick={() => onClose(true)}
                    >
                        Proceed Anyway
                    </button>
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default ImageWarningModal;
