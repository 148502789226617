import React from 'react';
import { BaseAssetReviewResultWithUserDetails } from '../types';
import StatusIndicator from './StatusIndicator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCalendar } from '@fortawesome/free-regular-svg-icons';
import { formatDate } from '../utils/timeUtils';
// import useNumberItems from '../hooks/useNumberItems';

interface AssetCardProps {
    asset: BaseAssetReviewResultWithUserDetails;
    imageUrl: string;
    onSelect: (asset: BaseAssetReviewResultWithUserDetails) => void;
    isSelected: boolean;
}

const AssetCard: React.FC<AssetCardProps> = ({
    asset,
    imageUrl,
    onSelect,
    isSelected,
}) => {
    const userName = asset.user_details
        ? `${asset.user_details.first_name} ${asset.user_details.last_name}`
        : 'Unknown User';
    return (
        <div
            className={`rule-result-card ${isSelected ? 'selected' : ''}`}
            onClick={() => onSelect(asset)}
        >
            <div className="main-image-container">
                <img
                    className="main-image"
                    src={imageUrl}
                    alt={`Thumbnail for ${asset.properties.asset_name}`}
                />
            </div>
            <div className="info-section">
                <div className="upper-container">
                    <p className="project-name">
                        {asset.properties.asset_name}
                    </p>
                    <StatusIndicator
                        decision={asset.approval_status}
                        isBig={false}
                    />
                </div>

                <div className="lower-container">
                    <div className="icon-value-container">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>
                            {formatDate(asset.run_info.timestamp, false, true)}
                        </p>
                    </div>
                    {userName && (
                        <div className="icon-value-container">
                            <FontAwesomeIcon icon={faUser} />
                            <p>{userName}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AssetCard;
