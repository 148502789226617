import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    AssetReviewResult,
    CommentActivity,
    StatusChangeActivity,
    UploadActivity,
    ApprovalStatus,
    Outcome,
    UserFeedbackActivity,
} from '../types';
import AssetDetailsInformation from './AssetDetailsInformation';
import AssetDetailsTitleBar from './AssetDetailsTitleBar';
import ImageCanvas from './ImageCanvas';
import AssetDetailsBackButton from './AssetDetailsBackButton';
import {
    changeApprovalStatus,
    deleteAsset,
    fetchAssetDetails,
    handleLibraryRequest,
    postComment,
    provideUserFeedback,
} from '../utils/backendServices';
import Spinner from './Spinner';
import { AssetContext } from '../contexts/AssetContext';
import { useNavigateWithinOrg } from '../utils/navigation';
import DeleteModal from './DeleteModal';
import { useLibraryContext } from '../contexts/LibraryContext';

const AssetDetails: FunctionComponent = () => {
    const { id } = useParams<{ id: string }>();
    const [reviewResult, setReviewResult] = useState<AssetReviewResult | null>(
        null,
    );
    const [activities, setActivities] = useState<
        (
            | StatusChangeActivity
            | CommentActivity
            | UploadActivity
            | UserFeedbackActivity
        )[]
    >([]);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [focussedRule, setFocussedRule] = useState<string | null>(null);
    const [hoveredRule, setHoveredRule] = useState<string | null>(null);

    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);
    const [assetNotFound, setAssetNotFound] = useState<boolean>(false);

    const navigate = useNavigateWithinOrg();
    const libraryContext = useLibraryContext();

    useEffect(() => {
        if (id) {
            fetchAssetDetails(id)
                .then((assetDetailResponse) => {
                    setReviewResult(assetDetailResponse.review_result);
                    setActivities(assetDetailResponse.activities);
                    setImageUrl(assetDetailResponse.image_url);
                })
                .catch((error) => {
                    console.error(error);
                    setAssetNotFound(true);
                });
        }
    }, []);

    // const handleSubmitFeedback = async (
    //     selectedRule: CheckResult,
    //     newOuctome: string,
    //     comment: string,
    // ) => {
    //     if (id) {
    //         setSelectedRule(null);
    //         setIsSubmittingFeedback(true);
    //         const response = await submitFeedbackForItem(
    //             id,
    //             selectedRule.display_name,
    //             {
    //                 comment: comment,
    //                 new_outcome: newOuctome,
    //             },
    //         );
    //         // TODO: Needs to be fixed
    //         // setItem(response.data);
    //         setIsSubmittingFeedback(false);
    //         handleLibraryRequest(libraryContext, 1, false).catch((error) => {
    //             console.log(error);
    //         });
    //     }
    // };

    const handleDeleteConfirmation = () => {
        if (reviewResult) {
            setIsDeleteModalVisible(false);
            setReviewResult(null);

            deleteAsset(reviewResult.id)
                .then(() => {
                    handleLibraryRequest(libraryContext)
                        .then(() => {
                            navigate('/library');
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((error) => {
                    console.error('Error deleting asset:', error);
                    alert('Error deleting asset');
                });
        }
    };

    if (assetNotFound) {
        return (
            <div className="detail-container">
                <div>
                    <AssetDetailsBackButton />
                    <h3>The visual was not found.</h3>
                </div>
            </div>
        );
    }

    if (!reviewResult || !imageUrl) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner title="Loading..." />
            </div>
        );
    }

    const handleApprovalStatusChange = async (
        assetId: string,
        newStatus: ApprovalStatus,
        reason?: string,
    ) => {
        setReviewResult(null);
        const assetDetailResponse = await changeApprovalStatus(assetId, {
            new_status: newStatus,
            reason,
        });
        setReviewResult(assetDetailResponse.review_result);
        setActivities(assetDetailResponse.activities);
    };

    const handleCommentSubmission = async (
        assetId: string,
        comment: string,
    ) => {
        const newActivities = await postComment(assetId, { content: comment });
        setActivities(newActivities);
    };

    const handleFeedbackSubmission = async (
        assetId: string,
        ruleId: string,
        newOutcome: Outcome,
        reason: string,
    ) => {
        setReviewResult(null);
        const assetDetailResponse = await provideUserFeedback(assetId, ruleId, {
            new_outcome: newOutcome,
            reason,
        });
        setReviewResult(assetDetailResponse.review_result);
        setActivities(assetDetailResponse.activities);
    };

    return (
        <AssetContext.Provider
            value={{
                state: {
                    reviewResult,
                    imageUrl,
                    activities,
                    focussedRule,
                    hoveredRule,
                },
                setters: { setFocussedRule, setHoveredRule },
                handlers: {
                    handleCommentSubmission,
                    handleApprovalStatusChange,
                    handleFeedbackSubmission,
                },
            }}
        >
            <>
                <DeleteModal
                    isActive={isDeleteModalVisible}
                    onClose={() => setIsDeleteModalVisible(false)}
                    onDelete={() => handleDeleteConfirmation()}
                />
                <div className="detail-container">
                    <div className="asset-details-container">
                        <div className="left-container">
                            <AssetDetailsBackButton />
                            <ImageCanvas />
                        </div>
                        <div className="right-container">
                            <AssetDetailsTitleBar
                                handleDelete={() =>
                                    setIsDeleteModalVisible(true)
                                }
                            />
                            <AssetDetailsInformation />
                        </div>
                    </div>
                </div>
            </>
        </AssetContext.Provider>
    );
};

export default AssetDetails;
